import Base, { ShippingState } from 'core/pages/Shipping/ShippingReading'
import { cloneDeep as _cloneDeep } from 'lodash'
import React from 'react'
import {
  ConfigurationDetail,
  DecodedItem,
  EncodingCounters,
  EncodingValidationRequest,
  EncodingValidationResponse,
  IdentifierType,
  ProductInfoField,
  Products,
  ShippingParcel,
  Shippings,
  TmrProduct,
  TmrTag,
} from 'stylewhere/api'
import {
  AntennaButton,
  Box,
  Button,
  EncodingForm,
  EncodingIdentifers,
  FullLoadingLayer,
  OperationReadingCounters,
  OperationReadingList,
  Page,
  RemoveMode,
  Spacer,
  Stoplight,
  TagCounter,
  WorkstationEmulationInfoBox,
} from 'stylewhere/components'
import { EncodingExtensions, ShippingExtensions } from 'stylewhere/extensions'
import { T, __ } from 'stylewhere/i18n'
import {
  AppStore,
  checkRequiredField,
  FormSchemaData,
  getDataFromSchema,
  OperationReadingProps,
  RemoteOperation,
  RfidReader,
  Router,
  Sounds,
} from 'stylewhere/shared'
import { EncodingOperationConfig, PackingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import {
  askUserConfirmation,
  BLOCKED_ERRORS,
  checkItemsCounterError,
  filterIgnoredAndErrorItems,
  getAskUserInputEveryTime,
  getAutomaticRestartAntenna,
  getCanForceAssocation,
  getChecklistItemsCount,
  getEnableUhfWrite,
  getEncodingAssociationStatus,
  getInitialType,
  getTimeoutValidateTags,
  getWithUserConfirmationMessage,
  hasAsyncConfirm,
  isExpectOnlyMandatoryIdentifiers,
  isModalError,
  isSkipValidation,
  showToast,
  showToastError,
  sleep,
} from 'stylewhere/shared/utils'

interface WithEncodingReadingState extends ShippingState {
  encodingValidation?: EncodingValidationRequest
  encodingValidationResponse?: EncodingValidationResponse
  encodingCreateResponse?: EncodingValidationResponse
  counters?: EncodingCounters
  renderIteration: number
  product?: TmrProduct
  tagsRead: TmrTag[]
  processing: boolean | 'nfcDecryption'
  associationStatus: 'TO_BE_READ' | 'CONFIRMED' | 'ERROR' | 'PROCESSING' | 'IGNORED' | 'SKIPPED' | 'WRITING' | 'READY'
  antennaOn: boolean
  forceAssociation: boolean
  pin: string
  starting: boolean
  configuration: any[]
  encodingCreateCounter: number
}

export default class WithEncodingReading extends Base<
  OperationReadingProps<WithEncodingReadingState>,
  WithEncodingReadingState
> {
  locationState = Router.getLocationState<WithEncodingReadingState>(this.props)
  embeddedEncodingOperation
  initialType
  isEncoding = false
  timer: NodeJS.Timeout | null = null
  timerReader?: any
  timerTags: NodeJS.Timeout | null = null
  timerNoRead: NodeJS.Timeout | null = null

  state: WithEncodingReadingState = {
    ...this.state,
    renderIteration: 0,
    formData: undefined,
    product: undefined,
    tagsRead: [],
    processing: false,
    associationStatus: 'TO_BE_READ',
    antennaOn: false,
    forceAssociation: false,
    pin: '',
    starting: false,
    configuration: [],
    encodingCreateCounter: 0,
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)

    const userSetAutostartAntenna: boolean =
      (await AppStore.getUserSetting('autostartAntenna', this.operation.id))?.value || this.operation.autostartAntenna
    const userSetAutoConfirmParcel: boolean =
      (await AppStore.getUserSetting('autoConfirmParcel', this.operation.id))?.value || false

    if (!this.operation.validateEncodingOperationCode) {
      showToastError(new Error('Missing embedded encoding operation code'), __(T.error.error), this.isModal)
      return this.goBack()
    }
    // get related encoding operation
    const encodingOperationConfig = RemoteOperation.getOperationConfig<EncodingOperationConfig>(
      this.operation.validateEncodingOperationCode
    )
    if (!encodingOperationConfig) {
      return this.goBack()
    }
    this.embeddedEncodingOperation = encodingOperationConfig

    // shipping related
    let parcel: ShippingParcel | undefined
    if (this.isBatchReadingMode) {
      try {
        if (!checkRequiredField(this.state.formData, this.formSchema)) {
          await this.openRequiredFieldsModal()
        }
        const createParcelResponse = await this.createParcel()
        parcel = createParcelResponse?.parcel
        if (!parcel) throw new Error('Missing parcel')
      } catch (error) {
        showToastError(error, __(T.error.error), this.isModal)
      }
    }

    this.setState(
      {
        parcel,
        sseStatus: !hasAsyncConfirm(this.operation),
        autostartAntenna: userSetAutostartAntenna,
        autoConfirmParcel: userSetAutoConfirmParcel,
      },
      this.attachSse
    )

    // encoding related
    if (this.initialType === 'identifier') {
      if (!isSkipValidation(this.embeddedEncodingOperation)) {
        this.setState({ starting: true }, this.initializeReader)
      } else {
        //get itemConfiuguration
        const config = await EncodingExtensions.getItemTypeConfiguration(this.embeddedEncodingOperation)
        if (config) {
          const encodingValidation: EncodingValidationRequest = {
            identifiers: [],
            operationId: this.embeddedEncodingOperation.id,
            placeId: AppStore.defaultWorkstation!.placeId,
          }
          this.setState(
            { configuration: config, forceAssociation: true, encodingValidation: encodingValidation },
            this.initializeReader
          )
        } else {
          showToastError(__(T.error.no_item_configuration), __(T.error.error), this.isModal)
        }
      }
    } else {
      this.initializeReader()
    }
    this.initialType = getInitialType(this.embeddedEncodingOperation)
  }

  componentWillUnmount = async () => {
    this.setState({ processing: false })
    this.clearTimerNoReads()
    this.clearTimerTags()
    this.clearTimerReader()
    this.clearTimer()
    await this.stopAntenna()
  }

  startAntenna = async () => {
    if (!RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.start(
        undefined,
        undefined,
        undefined,
        getAutomaticRestartAntenna(this.embeddedEncodingOperation)
      )
    }
  }

  stopAntenna = async () => {
    if (RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.stop()
    }
  }

  onBeforeInitializeReader() {
    // extensible
  }

  initializeReader = async () => {
    try {
      this.onBeforeInitializeReader()
      if (this.initialType === 'identifier') {
        RfidReader.setAutomaticStop(false)
      } else {
        RfidReader.setAutomaticStop(this.embeddedEncodingOperation.autostopAntennaTimeout > 0)
        RfidReader.setAutomaticStopTime(this.embeddedEncodingOperation.autostopAntennaTimeout)
      }
      RfidReader.setIgnoreReadingTagsWithPrefixes(
        this.embeddedEncodingOperation.options && this.embeddedEncodingOperation.options.ignoreReadingTagsWithPrefixes
          ? this.embeddedEncodingOperation.options.ignoreReadingTagsWithPrefixes
          : ''
      )
      RfidReader.setOnTagReadCallback(this.initialType === 'identifier' ? this.onTagReadIdentifier : this.onTagRead)
      RfidReader.onStartCallback = this.onStartCallback
      RfidReader.onStopCallback = this.onStopCallback
      if (this.initialType === 'identifier') {
        await this.startReader()
      }
    } catch (error) {
      if (!AppStore.getEmulation()) {
        showToastError((error as any).message ?? __(T.error.rfid_reader_initialization), __(T.error.error), false)
      }
    }
  }

  onStartCallback = () => {
    this.setState({ antennaOn: true, starting: false })
  }

  onStopCallbackIdentifierCheck = (tmpEncoding: EncodingValidationRequest) => {
    return (
      tmpEncoding && tmpEncoding.identifiers && !tmpEncoding.identifiers.every((idt) => idt._status === 'CONFIRMED')
    )
  }

  onStopCallback = () => {
    if (!AppStore.getEmulation()) {
      const tmpEncoding = this.state.encodingValidation
      if (this.initialType !== 'identifier') {
        RfidReader.setAutomaticStop(false)
        RfidReader.stopTimer()
      }
      if (tmpEncoding && this.onStopCallbackIdentifierCheck(tmpEncoding)) {
        tmpEncoding.identifiers
          .filter((id) => id.identifierType !== 'SIMPLE_ITEM_IDENTIFIER')
          .filter((id) => !id._read)
          .filter((id) => id._status !== 'ERROR' && id._status !== 'NFC_COLLISION' && id._status !== 'SKIPPED')
          .forEach((idf) => {
            idf._status = 'ERROR'
            idf._error = __(T.imbustatrice.dynamic_tag_missing, { role: idf.role })
          })
        this.setState({
          antennaOn: false,
          starting: false,
          associationStatus: 'ERROR',
          encodingValidation: tmpEncoding,
        })
      } else {
        this.setState({ antennaOn: false, starting: false })
      }
    } else {
      this.setState({ antennaOn: false, starting: false })
    }
  }

  checkPackingUnpack = async (error: Error, parcelCode: string, onUnpackConfirm?: () => Promise<void>) => {
    if (
      error.message === 'UNPACK' &&
      this.operation.type === 'PACKING' &&
      parcelCode &&
      (this.operation as PackingOperationConfig).allowUnpack !== 'false'
    ) {
      if (await askUserConfirmation(__(T.misc.warning), __(T.confirm.unpack), __(T.misc.cancel), __(T.misc.yes))) {
        await Shippings.unpackPacking(this.operation as PackingOperationConfig, parcelCode)
        onUnpackConfirm && (await onUnpackConfirm())
        return true
      } else {
        this.setState({ renderIteration: this.state.renderIteration + 1 })
        return true
      }
    } else {
      return false
    }
  }

  blankFormSchemaInput = () => {
    this.setState({ renderIteration: this.state.renderIteration + 1 })
  }

  onSubmitForm = async (formData: FormSchemaData) => {
    let parcel
    const payloadStartParcel: any = getDataFromSchema(formData, this.formSchema)
    try {
      const productCode = payloadStartParcel.product?.code ?? payloadStartParcel.productCode
      if (productCode) {
        // productCode in schema
        const _product = await Products.searchCodes([productCode])
        if (!_product || !_product.content || _product.content.length === 0) {
          showToastError('Product not found', 'Product Error', this.isModal, this.blankFormSchemaInput)
          return
        }
        if (_product.content.length > 1) {
          showToastError('Multiple products found', 'Product Error', this.isModal, this.blankFormSchemaInput)
          return
        }
        parcel = await Shippings.startParcel(this.operation, payloadStartParcel, this.operation.startParcelWithAsn)
      } else {
        // parcelCode in schema
        if (!payloadStartParcel.parcelCode) {
          showToastError('Parcel code is required', 'Start Parcel Error', this.isModal, this.blankFormSchemaInput)
          return
        }
        parcel = await Shippings.startParcel(this.operation, payloadStartParcel, this.operation.startParcelWithAsn)
        if (!parcel) {
          showToastError('Parcel not found', 'Start Parcel Error', this.isModal, this.blankFormSchemaInput)
          return
        }
      }

      const entriesItem: DecodedItem[] = []
      let tagsToAdd: string[] = []
      if (parcel && parcel.parcelEntries && parcel.parcelEntries.length > 0) {
        parcel.parcelEntries.map((entry) => {
          if (entry.item && entry.item !== null) {
            const uhfTag = entry.item.identifiers.filter((e) => e.type === 'UHF_TAG').flatMap(({ code }) => code)
            tagsToAdd = tagsToAdd.concat(uhfTag)
            entriesItem.push({
              item: entry.item,
              statuses: [],
              identifierCode: uhfTag.length > 0 ? uhfTag[0] : '',
            })
          }
        })
      }

      const res = await EncodingExtensions.getItemConfiguration(this.embeddedEncodingOperation, formData)
      // this.initiazeDataDogSession(formSchemaData)
      const autostartAntenna = this.state.autostartAntenna
      if (autostartAntenna) {
        this.setState(
          {
            items: entriesItem,
            initReaderTags: tagsToAdd,
            associationStatus: 'TO_BE_READ',
            encodingValidation: res.encodingValidation,
            encodingValidationResponse: res.encodingValidationResponse,
            formData,
            parcel,
          },
          this.startReader
        )
      } else {
        res.encodingValidation.identifiers.forEach((id) => {
          id._status = 'READY'
        })
        this.setState({
          items: entriesItem,
          initReaderTags: tagsToAdd,
          associationStatus: 'READY',
          encodingValidation: res.encodingValidation,
          encodingValidationResponse: res.encodingValidationResponse,
          formData,
          parcel,
        })
      }
    } catch (err) {
      if (await this.checkPackingUnpack(err as any, payloadStartParcel.parcelCode, () => this.onSubmitForm(formData)))
        return
      showToastError(err, 'Start Parcel Error', this.isModal, this.blankFormSchemaInput)
    }
  }

  startReader = async () => {
    try {
      // await this.initializeReader()
      RfidReader.setAutomaticStop(this.embeddedEncodingOperation.autostopAntennaTimeout > 0)
      RfidReader.setAutomaticStopTime(this.embeddedEncodingOperation.autostopAntennaTimeout)
      await RfidReader.start(
        undefined,
        undefined,
        undefined,
        getAutomaticRestartAntenna(this.embeddedEncodingOperation)
      )
    } catch (error) {
      if (!AppStore.getEmulation()) {
        showToastError((error as any).message ?? __(T.error.rfid_reader_initialization), __(T.error.error), false)
      }
    }
  }

  removeTagReadFromReader = (tags: TmrTag[]) => {
    const toRemove: string[] = []
    for (let t = 0; t < tags.length; t++) {
      toRemove.push(tags[t].uid ?? tags[t].epc)
    }
    RfidReader.removeTags(toRemove)
  }

  matchConfigurationTag = (identifiers) => {
    const { configuration } = this.state
    // console.log(configuration)
    // console.log(identifiers)
    return false
  }

  getFullWidthAssociationBox() {
    return true
  }

  getDisableRetry() {
    return false
  }

  getUpdatedCounters = (success) => {
    const tmp = this.state.counters
    if (tmp && success) {
      if (tmp.order) {
        tmp.order.encoded = (tmp.order.encoded ?? 0) + 1
      }
      if (tmp.row) {
        tmp.row.encoded = (tmp.row.encoded ?? 0) + 1
      }
    }
    return tmp
  }

  checkAttachAutomaticReaderStop = async (encodingValidationResponse) => {
    this.isEncoding = false
    if (this.embeddedEncodingOperation.autostopAntennaTimeout > 0) {
      const blockedErrors = encodingValidationResponse
        ? encodingValidationResponse.errors?.filter((e) => BLOCKED_ERRORS.includes(e.errorCode))
        : []
      if (blockedErrors.length === 0) {
        this.attachAutomaticReaderStop()
      } else {
        this.feedbackSoundKo()
        this.setState({ associationStatus: 'ERROR' })
        // if (blockedErrors.some((e) => e.errorCode === 'EXTRA_TAG')) {
        //   this.stopAntenna()
        // }
      }
    }
  }

  attachAutomaticReaderStop = () => {
    if (this.embeddedEncodingOperation.autostopAntennaTimeout > 0) {
      this.clearTimerReader()
      this.timerReader = setTimeout(async () => {
        this.setState({ associationStatus: 'ERROR' })
        await this.stopAntenna()
        if (this.checkIfForceCreate('yes')) {
          this.isEncoding = true
          this.setState(
            { forceAssociation: true, pin: '', encodingCreateCounter: this.state.encodingCreateCounter + 1 },
            this.create
          )
        } else if (this.checkIfForceCreate('withUserConfirmation')) {
          const { encodingValidationResponse } = this.state
          const errors =
            encodingValidationResponse && encodingValidationResponse.errors ? encodingValidationResponse.errors : []
          // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
          if (
            errors.length == 1 &&
            errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
            isExpectOnlyMandatoryIdentifiers(this.embeddedEncodingOperation)
          ) {
            this.call_force_encoding(false)
          }
          // else {
          //   const ok = await askUserConfirmation(
          //     __(T.misc.attention),
          //     this.getWithUserConfirmationMessage(errors, encodingValidationResponse)
          //   )
          //   if (ok) {
          //     this.call_force_encoding(false)
          //   } else {
          //     this.clear()
          //     this.isEncoding = false
          //   }
          // }
          // } else {
          // this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, () => this.create(true))
        }
      }, this.embeddedEncodingOperation.autostopAntennaTimeout)
    }
  }

  getInitFormSchemaData = () => {
    return this.initialType === 'formSchema' &&
      this.operation.formSchema &&
      Object.keys(this.operation.formSchema).length > 0
      ? {}
      : { product: { code: '' }, wam: '' }
  }

  clearTimerNoReads = () => {
    if (this.timerNoRead) {
      clearTimeout(this.timerNoRead)
      this.timerNoRead = null
    }
  }

  clearTimerTags = () => {
    if (this.timerTags) {
      clearTimeout(this.timerTags)
      this.timerTags = null
    }
  }

  startTimerTags = () => {
    const timeoutValidateTags = this.embeddedEncodingOperation.options.timeoutValidateTags ?? 1000
    this.clearTimerNoReads()
    this.clearTimerTags()
    this.timerTags = setTimeout(async () => {
      if (this.state.encodingValidation) {
        this.validateReadTags()
      } else {
        this.validateIdentifiersTag()
      }
    }, timeoutValidateTags)
  }

  validateReadTags = async () => {
    if (this.isEncoding) return
    const { formData } = this.state
    this.isEncoding = true
    // this.encodingStart = new Date()
    const tmpTagsRead = this.state.tagsRead
    const tmpEncodingValidation = this.state.encodingValidation
    if (tmpEncodingValidation) {
      for (let t = 0; t < tmpTagsRead.length; t++) {
        await EncodingExtensions.onTagRead(tmpEncodingValidation, tmpTagsRead[t], this.embeddedEncodingOperation)
      }
      try {
        const shippingDataPayload: any = this.getConfirmData()
        const res = await EncodingExtensions.validate(
          {
            ...tmpEncodingValidation,
            operationAttributes: { ...tmpEncodingValidation?.operationAttributes, ...shippingDataPayload.attributes },
          },
          true,
          false,
          getEnableUhfWrite(this.embeddedEncodingOperation) !== 'no'
        )
        // if (!this.datadogSession)
        //   this.initializeDataDogSession({
        //     identifier: tmpTagsRead[0].epc ?? tmpTagsRead[0].uid ?? tmpTagsRead[0].barcode,
        //   })
        this.setState(
          {
            encodingValidationResponse: res.encodingValidationResponse,
            encodingValidation: tmpEncodingValidation,
          },
          this.onTagReadValidateResponse
        )
      } catch (error) {
        this.isEncoding = false
        this.onClear()
        this.encodingError(error, 'Unknown error during encoding validation')
      }
    } else {
      this.isEncoding = false
      this.onClear()
    }
  }

  validateIdentifiersTag = async () => {
    // TODO
    // const { tagsRead } = this.state
    // const tmpEncodingValidation: EncodingValidationRequest = {
    //   identifiers: [],
    //   operationId: this.embeddedEncodingOperation.id,
    //   placeId: AppStore.defaultWorkstation!.placeId,
    // }
    // for (let t = 0; t < tagsRead.length; t++) {
    //   await EncodingExtensions.onTagRead(tmpEncodingValidation, tagsRead[t], this.embeddedEncodingOperation)
    // }
    // try {
    //   const res = await EncodingExtensions.validate(
    //     tmpEncodingValidation,
    //     false,
    //     false,
    //     getEnableUhfWrite(this.embeddedEncodingOperation) !== 'no'
    //   )
    //   // if (!this.datadogSession)
    //   //   this.initializeDataDogSession({ identifier: tagsRead[0].epc ?? tagsRead[0].uid ?? tagsRead[0].barcode })
    //   if (
    //     res.encodingValidationResponse.item &&
    //     res.encodingValidationResponse.item.product &&
    //     res.encodingValidationResponse.item.product !== null
    //   ) {
    //     await this.addIdentifierRole(res)
    //     await this.addIdentifierConfiguration(res)
    //     if (res.encodingValidationResponse.success) {
    //       this.isEncoding = true
    //       // this.encodingStart = new Date()
    //     }
    //     this.setState(
    //       {
    //         encodingValidationResponse: res.encodingValidationResponse,
    //         encodingValidation: res.encodingValidation,
    //         processing: res.encodingValidationResponse.success,
    //       },
    //       res.encodingValidationResponse.success ? this.onTagReadValidateResponse : this.startTimerNoReads
    //     )
    //   } else {
    //     this.setState({ processing: false })
    //     await this.stopAntenna()
    //     this.removeTagReadFromReader(tagsRead)
    //     let modalText = __(T.error.tags_errors)
    //     if (res && res.encodingValidationResponse && res.encodingValidationResponse.errors) {
    //       let errorCheck = res.encodingValidationResponse.errors.find((e) => e.errorCode === 'MISSING_PRODUCT_INFO')
    //       if (errorCheck) {
    //         modalText = __(T.error.only_virgin_tag)
    //       } else {
    //         errorCheck = res.encodingValidationResponse.errors.find(
    //           (e) => e.errorCode === 'Tag Mismatch' || e.errorCode === 'TAG_MISMATCH'
    //         )
    //         if (errorCheck) {
    //           modalText = __(T.error.tags_mismatch_error)
    //         }
    //       }
    //     }
    //     showToastError(modalText, __(T.error.error), true, this.closeVirginTagModal, 'OK')
    //     this.feedbackSoundKo()
    //   }
    // } catch (error) {
    //   this.setState({ processing: false })
    //   this.removeTagReadFromReader(tagsRead)
    //   showToastError(
    //     (error as any).message ?? 'Unknown error during encoding validation',
    //     __(T.error.error),
    //     this.isModal
    //   )
    // }
  }

  startTimerNoReads = async () => {
    const { encodingValidation } = this.state
    if (encodingValidation) {
      if (this.checkIfForceCreate('yes')) {
        this.isEncoding = true
        // this.encodingStart = new Date()
        this.setState(
          { forceAssociation: true, pin: '', encodingCreateCounter: this.state.encodingCreateCounter + 1 },
          this.create
        )
      } else if (this.checkIfForceCreate('withUserConfirmation')) {
        const { encodingValidationResponse } = this.state
        const errors =
          encodingValidationResponse && encodingValidationResponse.errors ? encodingValidationResponse.errors : []
        // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
        if (
          errors.length == 1 &&
          errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
          isExpectOnlyMandatoryIdentifiers(this.embeddedEncodingOperation)
        ) {
          this.call_force_encoding(false)
        } else {
          const ok = await askUserConfirmation(
            __(T.misc.attention),
            getWithUserConfirmationMessage(errors, encodingValidationResponse)
          )
          if (ok) {
            this.call_force_encoding(false)
          } else {
            this.clear()
            this.isEncoding = false
          }
        }
      } else {
        //if (!isSkipValidation(this.embeddedEncodingOperation)) await this.startAntenna()
        const timer = this.embeddedEncodingOperation.options.timeoutTagsNoReads
          ? this.embeddedEncodingOperation.options.timeoutTagsNoReads
          : 5000
        if (timer > 0) {
          this.clearTimerNoReads()
          this.timerNoRead = setTimeout(async () => {
            //await this.stopAntenna()
            if (isSkipValidation(this.embeddedEncodingOperation)) {
              this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, this.create)
            } else {
              this.feedbackSoundKo()
              this.setState({ associationStatus: 'ERROR' })
            }
          }, timer)
        }
      }
    }
  }

  onTagReadIdentifier = async (tag: TmrTag) => {
    // TODO
    // const { encodingValidation, encodingCreateCounter } = this.state
    // const tmpTagsRead = this.state.tagsRead
    // const identifierType = getTagType(tag)
    // if (isSkipValidation(this.embeddedEncodingOperation)) {
    //   tmpTagsRead.push(tag)
    //   await EncodingExtensions.onTagRead(encodingValidation!, tag, this.embeddedEncodingOperation)
    //   const matches = this.matchConfigurationTag(encodingValidation?.identifiers)
    //   if (matches) {
    //     this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
    //   } else {
    //     this.setState({ tagsRead: tmpTagsRead, encodingValidation: encodingValidation }, this.startTimerNoReads)
    //   }
    // } else {
    //   if (!encodingValidation && identifierType === 'UHF_TAG') {
    //     tmpTagsRead.push(tag)
    //     this.setState({ tagsRead: tmpTagsRead }, this.startTimerTags)
    //   } else {
    //     tmpTagsRead.push(tag)
    //     if (encodingValidation) {
    //       await EncodingExtensions.onTagRead(encodingValidation, tag, this.embeddedEncodingOperation)
    //       this.setState({ tagsRead: tmpTagsRead, encodingValidation: encodingValidation }, this.startTimerTags)
    //     } else {
    //       this.setState({ tagsRead: tmpTagsRead })
    //     }
    //   }
    // }
  }

  feedbackSoundOk = () => {
    const { options } = this.embeddedEncodingOperation
    if (options.enableSoundFeedbackOk && options.enableSoundFeedbackOk === 'true') {
      Sounds.success()
    }
  }

  feedbackSoundKo = () => {
    const { options } = this.embeddedEncodingOperation
    if (options.enableSoundFeedbackKo && options.enableSoundFeedbackKo === 'true') {
      Sounds.error()
    }
  }

  feedbackSoundConfirmation = () => {
    const { options } = this.embeddedEncodingOperation
    if (options.enableSoundFeedbackConfirm && options.enableSoundFeedbackConfirm === 'true') {
      Sounds.confirmation()
    }
  }

  encodingError = (error?: any, text?: string) => {
    this.feedbackSoundKo()
    showToastError(
      error && error.message ? error.message : text ?? 'Unknown error during encoding validation',
      __(T.error.error),
      this.isModal
    )
  }

  additionalOnTagReadCheck = (tag: TmrTag) => {
    return true
  }

  getIgnoreUnknownTags = () => {
    const { options } = this.embeddedEncodingOperation
    return options && options.ignoreUnknownTags && options.ignoreUnknownTags === 'yes'
  }

  clearTimerReader = () => {
    if (this.timerReader) {
      clearTimeout(this.timerReader)
      this.timerReader = null
    }
  }

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  resetValidateResponse = () => {
    this.setState({
      encodingValidation: undefined,
      encodingValidationResponse: undefined,
      counters: undefined,
      encodingCreateResponse: undefined,
    })
  }

  clear = () => {
    if (getInitialType(this.embeddedEncodingOperation) !== 'identifier') {
      this.clearReads()
    } else {
      this.onClear()
    }
  }

  onClear = async (addtags = false) => {
    const { parcel, encodingValidation, renderIteration } = this.state
    // shipping related
    if (this.isBatchReadingMode && parcel) {
      await this.updateParcel('CLEAR', [], parcel.code)
    }
    this.setState({ items: [], parcel: undefined, formData: undefined, renderIteration: renderIteration + 1 })

    // encoding related
    this.clearTimer()
    if (this.initialType !== 'identifier') {
      await this.stopAntenna()
    } else {
      this.clearTimerTags()
      this.clearTimerNoReads()
      await this.startAntenna()
    }
    // this.datadogSession = undefined
    RfidReader.clear()
    if (addtags && encodingValidation && encodingValidation.identifiers) {
      RfidReader.tags = encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .map(
          (idf) =>
            ({
              uid: idf.identifierType === 'NFC_TAG' && idf.code,
              epc: idf.identifierType === 'UHF_TAG' && idf.code,
              tid: idf.tid || '',
            } as any)
        )
    }
    this.setState(
      {
        tagsRead: [],
        associationStatus: 'TO_BE_READ',
        pin: '',
        forceAssociation: false,
        encodingCreateCounter: 0,
        processing: false,
      },
      this.resetValidateResponse
    )
    this.isEncoding = false
  }

  afterClearReads() {
    //extensible
  }

  clearReads = async (addtags = false, noask = false) => {
    const { encodingValidation, items, parcel } = this.state

    // stop reading before clearing tags to avoid double readings
    getAskUserInputEveryTime(this.embeddedEncodingOperation) && !noask && (await this.stopAntenna())

    this.clearTimer()
    RfidReader.clear()
    // this.datadogSession = undefined
    if (addtags && encodingValidation && encodingValidation.identifiers) {
      RfidReader.tags = encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .map(
          (idf) =>
            ({
              uid: idf.identifierType === 'NFC_TAG' && idf.code,
              epc: idf.identifierType === 'UHF_TAG' && idf.code,
              tid: idf.tid || '',
            } as any)
        )
    }

    if (getAskUserInputEveryTime(this.embeddedEncodingOperation) && !noask) {
      RfidReader.setAutomaticStop(false)
      await this.stopAntenna()
      this.setState(
        {
          formData: this.getInitFormSchemaData(),
          associationStatus: 'TO_BE_READ',
          tagsRead: [],
          pin: '',
          forceAssociation: false,
          encodingCreateCounter: 0,
          // targetTag: '',
          // targetTid: '',
          // tagToWrite: '',
          // serial: '',
          processing: false,
        },
        this.resetValidateResponse
      )
    } else {
      //reset automatic stop antenna
      RfidReader.setAutomaticStop(this.embeddedEncodingOperation.autostopAntennaTimeout > 0)
      RfidReader.setAutomaticStopTime(this.embeddedEncodingOperation.autostopAntennaTimeout)
      const itemsCount = items.filter(filterIgnoredAndErrorItems).length
      const { expecteds, overQty, unexpecteds } = getChecklistItemsCount(
        items,
        this.operation,
        ShippingExtensions,
        parcel?.checklist,
        parcel?.products
      )

      if (this.state.autostartAntenna && itemsCount < expecteds && !overQty && !unexpecteds) {
        await this.startAntenna()

        //ricalcolare in base alla configurazione il reset
        const tmpEncoding = this.state.encodingValidation
        const identifiers = EncodingExtensions.resetIdentifiersEncodingValition(
          this.embeddedEncodingOperation,
          this.state.encodingValidationResponse,
          this.state.encodingValidation
        )
        if (tmpEncoding) tmpEncoding.identifiers = identifiers

        this.setState({
          encodingValidation: tmpEncoding,
          encodingCreateResponse: undefined,
          associationStatus: 'TO_BE_READ',
          tagsRead: [],
          pin: '',
          forceAssociation: false,
          encodingCreateCounter: 0,
          // targetTag: '',
          // targetTid: '',
          // tagToWrite: '',
          // serial: '',
          processing: false,
        })
      } else {
        this.setState({
          encodingCreateResponse: undefined,
          tagsRead: [],
          pin: '',
          forceAssociation: false,
          encodingCreateCounter: 0,
          processing: false,
        })
      }
    }
    this.isEncoding = false
    this.afterClearReads()
  }

  resetAfterCreate = async () => {
    if (getInitialType(this.embeddedEncodingOperation) !== 'identifier') {
      this.clearReads()
    } else {
      this.onClear()
    }
  }

  checkValidateAfterRemoveUnknownTags = () => {
    const { encodingValidation } = this.state
    if (encodingValidation) {
      const findCode = encodingValidation.identifiers.find((idt) => idt.code && idt.code !== '')
      if (findCode) {
        this.onTagReadValidate()
      } else {
        this.isEncoding = false
      }
    } else {
      this.isEncoding = false
    }
  }

  onTagRead = async (tag: TmrTag) => {
    //added a sleep to destroy correctly the timers or wait isEncoding flag
    await sleep(Math.random() * 500)

    const { encodingValidation } = this.state
    if (!encodingValidation || !encodingValidation.identifiers) return

    if (this.isEncoding) {
      this.removeTagReadFromReader([tag])
      return
    }
    if (!this.additionalOnTagReadCheck(tag)) return
    // if time to validate is 0 then set isEncoding to true immediately for not have validated in parallel
    const timeoutValidateTags = getTimeoutValidateTags(this.embeddedEncodingOperation, this.getIgnoreUnknownTags())
    if (timeoutValidateTags !== undefined && timeoutValidateTags === 0) {
      this.isEncoding = true
    }
    try {
      RfidReader.setAutomaticStop(false)
      RfidReader.stopTimer()
      this.clearTimerReader()
      this.clearTimer()
      await EncodingExtensions.onTagRead(encodingValidation!, tag, this.embeddedEncodingOperation)
      this.setState({ encodingValidation }, () => {
        // if (this.datadogSession) {
        //   if (tag.uid) {
        //     this.datadogSession.nfcRead = {
        //       offsetMs: Date.now() - this.datadogSession.startTime.getTime(),
        //       nfcRead: tag.uid,
        //     }
        //     datadogRum.addAction('nfcRead', this.datadogSession)
        //   } else if (tag.epc) {
        //     this.datadogSession.uhfRead = {
        //       offsetMs: Date.now() - this.datadogSession.startTime.getTime(),
        //       uhfRead: tag.epc,
        //     }
        //     datadogRum.addAction('uhfRead', this.datadogSession)
        //   }
        // }
        this.onTagReadTimer()
      })
    } catch (error) {}
  }

  onTagReadTimer = () => {
    const timeoutValidateTags = getTimeoutValidateTags(this.embeddedEncodingOperation, this.getIgnoreUnknownTags())
    if (timeoutValidateTags !== undefined && timeoutValidateTags > 0) {
      this.timer = setTimeout(() => {
        this.onTagReadValidate()
      }, timeoutValidateTags)
    } else {
      this.onTagReadValidate()
    }
  }

  onTagReadValidate = async () => {
    const { encodingValidation } = this.state
    try {
      this.isEncoding = true
      // this.encodingStart = new Date()
      const shippingDataPayload: any = this.getConfirmData()
      const res = await EncodingExtensions.validate(
        {
          ...encodingValidation!,
          operationAttributes: { ...encodingValidation?.operationAttributes, ...shippingDataPayload.attributes },
        },
        true,
        false,
        getEnableUhfWrite(this.embeddedEncodingOperation) !== 'no'
      )
      this.setState(
        {
          encodingValidationResponse: res.encodingValidationResponse,
          encodingValidation: res.encodingValidation,
        },
        this.onTagReadValidateResponse
      )
    } catch (error) {
      this.clearReads(false, true)
      this.encodingError(error, 'Unknown error during encoding validation')
    } finally {
      this.isEncoding = false
    }
  }

  getErrorCodesToWhichStopAntenna = () => {
    return ['EXTRA_TAG']
  }

  onTagReadValidateResponse = async () => {
    const { encodingValidationResponse, encodingCreateCounter } = this.state
    if (encodingValidationResponse) {
      if (encodingValidationResponse.success) {
        // controllo se non devo fare operazioni di scrittura
        if (encodingValidationResponse.operationToPerform === 'NONE') {
          await this.onValidationOperationNone()
        } else {
          this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
        }
      } else {
        // this.startTimerNoReads()
        const errors = encodingValidationResponse.errors ?? []
        const errorCodesToWhichStopAntenna = this.getErrorCodesToWhichStopAntenna()
        if (errors.some((e) => errorCodesToWhichStopAntenna.includes(e.errorCode))) {
          this.stopAntenna()
          return
        }
        if (this.checkIfForceCreate('yes')) {
          this.call_force_encoding(true)
        } else if (this.checkIfForceCreate('withUserConfirmation')) {
          // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
          if (
            errors.length == 1 &&
            errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
            isExpectOnlyMandatoryIdentifiers(this.embeddedEncodingOperation)
          ) {
            this.call_force_encoding(true)
          } else {
            this.stopAntenna()
            this.isEncoding = false
            const ok = await askUserConfirmation(
              __(T.misc.attention),
              getWithUserConfirmationMessage(errors, encodingValidationResponse)
            )
            if (ok) {
              this.call_force_encoding(true)
            }
          }
        } else {
          this.checkAttachAutomaticReaderStop(encodingValidationResponse)
        }
      }
    }
  }

  // onTagReadValidateResponse = async () => {
  //   const { encodingValidationResponse, encodingValidation, encodingCreateCounter } = this.state
  //   if (encodingValidationResponse) {
  //     // remove ignored tags if request from operation option
  //     if (
  //       this.getIgnoreUnknownTags() &&
  //       encodingValidation &&
  //       encodingValidationResponse.errors.filter((el) => el.errorCode === 'UNKNOWN_TAG').length > 0
  //     ) {
  //       let resetIdentifiers = false
  //       const identifiers = encodingValidation.identifiers ?? []
  //       const newIdentifiers: ConfigurationDetail[] = []
  //       let find
  //       identifiers.map((idt) => {
  //         const checkUnknown = encodingValidationResponse.errors.find(
  //           (el) => el.errorCode === 'UNKNOWN_TAG' && el.ref === idt.code
  //         )
  //         if (!checkUnknown) {
  //           if (idt._added) {
  //             // to management case with code not associated at same identifierType
  //             find = identifiers.find((f) => !f._added && idt.identifierType === f.identifierType)
  //             if (find) {
  //               newIdentifiers.push({
  //                 id: find.id ?? '',
  //                 creationDate: find.creationDate ?? '',
  //                 identifierType: find.identifierType,
  //                 role: find.role ?? '',
  //                 optional: find.optional ?? false,
  //                 regexFilter: find.regexFilter ?? '',
  //                 code: idt.code,
  //                 _status: idt.code && idt.code !== '' ? 'CONFIRMED' : 'PROCESSING',
  //               })

  //               resetIdentifiers = true
  //             } else {
  //               resetIdentifiers = true
  //               newIdentifiers.push(idt)
  //             }
  //           } else {
  //             resetIdentifiers = true
  //             newIdentifiers.push(idt)
  //           }
  //         } else if (!idt._added) {
  //           resetIdentifiers = true
  //           newIdentifiers.push({
  //             id: idt.id ?? '',
  //             creationDate: idt.creationDate ?? '',
  //             identifierType: idt.identifierType,
  //             role: idt.role ?? '',
  //             optional: idt.optional ?? false,
  //             regexFilter: idt.regexFilter ?? '',
  //           })
  //         }
  //       })

  //       if (resetIdentifiers) {
  //         const finalIdentifiers: ConfigurationDetail[] = newIdentifiers.filter((f) => f.code && f.code !== '')
  //         newIdentifiers.find((idt) => {
  //           find = finalIdentifiers.find((f) => f.role === idt.role && f.identifierType === idt.identifierType)
  //           if (!find) {
  //             finalIdentifiers.push(idt)
  //           }
  //         })
  //         this.setState(
  //           { encodingValidation: { ...encodingValidation, identifiers: finalIdentifiers } },
  //           this.checkValidateAfterRemoveUnknownTags
  //         )
  //         return
  //       }
  //     }

  //     if (encodingValidationResponse.errors && encodingValidationResponse.errors.length > 0) {
  //       const checkMissing = encodingValidationResponse.errors.find((el) => el.errorCode === 'MISSING_MANDATORY_TAG')
  //       if (checkMissing) {
  //         this.isEncoding = false
  //         return
  //       }
  //     }

  //     if (
  //       encodingValidationResponse.operationToPerform === 'NONE' &&
  //       this.embeddedEncodingOperation.options.forceTagRewrite !== 'true'
  //     ) {
  //       // await this.sendMixpanelEventData('Encoding: Item Previously Associated')
  //       await this.onValidationOperationNone()
  //     }
  //     // else if (
  //     //   encodingValidationResponse.operationToPerform === 'NONE' &&
  //     //   this.embeddedEncodingOperation.options.forceTagRewrite === 'true'
  //     // ) {
  //     //   this.forceTagRewrite()
  //     // }
  //     else if (
  //       encodingValidationResponse.operationToPerform === 'UPDATE_ONLY_PRODUCT' ||
  //       encodingValidationResponse.operationToPerform === 'UPDATE_WAM'
  //     ) {
  //       this.feedbackSoundConfirmation()

  //       const readedTag = encodingValidation?.identifiers
  //         ? encodingValidation?.identifiers.length === 1
  //           ? encodingValidation?.identifiers[0]
  //           : encodingValidation?.identifiers?.length > 1
  //           ? encodingValidation?.identifiers.find((id) => id._status === 'CONFIRMED')
  //           : undefined
  //         : undefined
  //       const ok = await askUserConfirmation(
  //         __(T.misc.attention),
  //         __(T.misc.update_only_product),
  //         undefined,
  //         undefined,
  //         readedTag
  //           ? {
  //               identifierCode: readedTag.code,
  //             }
  //           : undefined
  //       )
  //       if (ok) {
  //         this.setState(
  //           { encodingCreateCounter: encodingCreateCounter + 1 },
  //           // this.checkWriteTag // per il momento la escludiamo e chiamiamo direttamente la create
  //           this.create
  //         )
  //       } else {
  //         if (getAskUserInputEveryTime(this.embeddedEncodingOperation)) {
  //           await this.stopAntenna()
  //         }
  //         this.clear()
  //         this.isEncoding = false
  //         // await this.sendMixpanelEventData('Encoding: Update product not confirmed')
  //       }
  //     } else if (encodingValidationResponse.operationToPerform === 'FAIL') {
  //       // await this.sendMixpanelEventData('Encoding: error')
  //       this.setState({ associationStatus: 'ERROR', processing: false })
  //       this.feedbackSoundKo()
  //       this.isEncoding = false
  //     } else if (encodingValidationResponse.operationToPerform === 'FORCE_ITEM_CREATION') {
  //       this.clearTimerReader()
  //       this.checkEncodingForceOption()
  //     } else {
  //       this.setState(
  //         { encodingCreateCounter: encodingCreateCounter + 1 },
  //         // this.checkWriteTag // per il momento la escludiamo e chiamiamo direttamente la create
  //         this.create
  //       )
  //     }
  //   } else {
  //     // await this.sendMixpanelEventData('Encoding: error')
  //     this.feedbackSoundKo()
  //     this.setState({ processing: false })
  //     this.isEncoding = false
  //   }
  // }

  addTagToList = (encodingValidationResponse: EncodingValidationResponse) => {
    const tag: any = {}
    const epc = encodingValidationResponse.item.identifiers.find((idf) => idf.type === 'UHF_TAG')?.code
    const nfc = encodingValidationResponse.item.identifiers.find((idf) => idf.type === 'NFC_TAG')?.code
    if (epc) tag.epc = epc
    if (nfc) tag.uid = nfc

    RfidReader.onTagReadCallback = undefined
    this.setRfidReaderDecode()
    RfidReader.removeTags([epc ?? '', nfc ?? ''])
    RfidReader.onTagRead(JSON.stringify(tag))
  }

  onValidationOperationNone = async () => {
    const { encodingValidationResponse } = this.state
    await this.stopAntenna()
    RfidReader.clear()
    this.setState({ processing: false, associationStatus: 'SKIPPED' })
    if (this.embeddedEncodingOperation.options.onItemAlreadyEncodedFeedback === 'confirmModal') {
      AppStore.showFeedbackModal(
        __(T.misc.attention),
        __(T.messages.encoding_success_none),
        () => {},
        __(T.misc.confirm)
      )
    } else {
      showToast({ status: 'success', description: __(T.messages.encoding_success_none) })
    }
    this.feedbackSoundOk()

    if (encodingValidationResponse) {
      // if item is already associated we should list it
      this.addTagToList(encodingValidationResponse)
    }

    setTimeout(async () => {
      this.resetAfterCreate()
    }, this.embeddedEncodingOperation.options.resetReadingsAfterOperation || 300)
  }

  checkIfForceCreate = (value) => {
    const { encodingValidationResponse } = this.state
    let canForce = false
    if (getCanForceAssocation(this.embeddedEncodingOperation) === value) {
      const notForcible = encodingValidationResponse?.errors?.find((e) => !e.forcible)
      //if only errors with forcible = true then force create
      canForce = !notForcible
    }
    return canForce
  }

  checkEncodingForceOption = async () => {
    const { encodingValidationResponse } = this.state
    if (encodingValidationResponse) {
      const encodingWrite = this.initialType !== 'identifier'
      if (this.checkIfForceCreate('yes')) {
        this.call_force_encoding(encodingWrite)
      } else if (this.checkIfForceCreate('withUserConfirmation')) {
        const errors = encodingValidationResponse.errors ?? []
        // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
        if (
          errors.length == 1 &&
          errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
          isExpectOnlyMandatoryIdentifiers(this.embeddedEncodingOperation)
        ) {
          this.call_force_encoding(encodingWrite)
        } else {
          const ok = await askUserConfirmation(
            __(T.misc.attention),
            getWithUserConfirmationMessage(errors, encodingValidationResponse)
          )
          if (ok) {
            this.call_force_encoding(encodingWrite)
          } else {
            this.clear()
            this.isEncoding = false
          }
        }
      } else {
        if (encodingWrite) {
          this.checkAttachAutomaticReaderStop(encodingValidationResponse)
        } else {
          const timer = this.embeddedEncodingOperation.options.timeoutTagsNoReads
            ? this.embeddedEncodingOperation.options.timeoutTagsNoReads
            : 5000
          if (timer > 0) {
            this.clearTimerNoReads()
            this.timerNoRead = setTimeout(async () => {
              if (isSkipValidation(this.embeddedEncodingOperation)) {
                this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, this.create)
              } else {
                this.feedbackSoundKo()
                this.setState({ associationStatus: 'ERROR' })
              }
            }, timer)
          }
        }
      }
    } else {
      this.feedbackSoundKo()
      this.setState({ processing: false })
      this.isEncoding = false
    }
  }

  call_force_encoding = (write) => {
    const { encodingCreateCounter } = this.state
    // if (!write) {
    this.isEncoding = true
    // this.encodingStart = new Date()
    this.setState({ forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
    // } else {
    //   this.setState(
    //     { forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 },
    //     this.checkWriteTag
    //   )
    // }
  }

  parcelIsSuitableForConfirmation = () => {
    const { parcel, items } = this.state
    if (!parcel) return false
    const itemsCount = items.filter(filterIgnoredAndErrorItems).length
    const { expecteds, overQty, unexpecteds } = getChecklistItemsCount(
      items,
      this.operation,
      ShippingExtensions,
      parcel.checklist,
      parcel.products
    )
    return itemsCount === expecteds && !overQty && !unexpecteds
  }

  onItemsDecodedCallback = async () => {
    RfidReader.setOnTagReadCallback(this.initialType === 'identifier' ? this.onTagReadIdentifier : this.onTagRead)
  }

  onParcelConfirmIsSuitable = async () => {
    if (this.state.autoConfirmParcel) {
      await this.antennaRef.current.stopReader()
      RfidReader.setAutomaticStop(false)
      RfidReader.stopTimer()
      setTimeout(() => {
        this.setState({ confirming: true }, () => {
          this.onConfirm()
        })
      }, Math.max(this.embeddedEncodingOperation.options.resetReadingsAfterOperation, 1500) - 1500)
    }
  }

  create = async () => {
    const { forceAssociation, pin, encodingValidation, encodingValidationResponse, tagsRead, formData } = this.state
    if (encodingValidation) {
      await this.stopAntenna()
      const skipValidation = isSkipValidation(this.embeddedEncodingOperation)
      try {
        let res
        if (forceAssociation) {
          res = await EncodingExtensions.force_create(
            { ...encodingValidation, itemAttributes: formData.attributes ?? {} },
            pin
          )
        } else
          res = await EncodingExtensions.create({
            ...encodingValidation,
            itemAttributes: formData.attributes ?? {},
          })
        if (!res) throw new Error(__(T.error.item_creation_error))

        const updatedCounters = this.getUpdatedCounters(res.success)
        if (!skipValidation) {
          this.setState({
            encodingCreateResponse: res,
            processing: false,
            associationStatus: getEncodingAssociationStatus(res, encodingValidationResponse),
            counters: updatedCounters,
          })
        } else {
          await EncodingExtensions.set_identifiers_status(
            encodingValidation,
            res,
            getEnableUhfWrite(this.embeddedEncodingOperation) !== 'no'
          )
          this.setState({
            encodingCreateResponse: res,
            encodingValidationResponse: res,
            encodingValidation: encodingValidation,
            processing: false,
            associationStatus: getEncodingAssociationStatus(res, encodingValidationResponse),
            counters: updatedCounters,
          })
        }
        if (res.success) {
          // if (this.datadogSession) {
          //   this.datadogSession.endTime = new Date()
          //   this.datadogSession.operationSuccessOffsetMs =
          //     this.datadogSession.endTime.getTime() - this.datadogSession.startTime.getTime()
          //   datadogRum.addAction('encodingSuccess', this.datadogSession)
          // }
          // await this.sendMixpanelEventData('Encoding: Item Associated')
          await AppStore.increaseDailyEncodedItems(this.embeddedEncodingOperation.code)
          showToast({ status: 'success', description: __(T.messages.encoding_success) })
          this.feedbackSoundOk()
        } else {
          // await this.sendMixpanelEventData('Encoding: error')
          this.encodingError(undefined, __(T.error.item_creation_error))
        }
        //if success reset operation after time in milliseconds
        if (res.success) {
          // hook up with shipping reading method, adding tag to the list
          this.addTagToList(res)

          setTimeout(async () => {
            this.resetAfterCreate()
          }, this.embeddedEncodingOperation.options.resetReadingsAfterOperation || (skipValidation ? 4000 : 300))
        } else {
          this.isEncoding = false
          if (!forceAssociation) {
            await this.startAntenna()
          }
          this.setState({ processing: false })
        }
      } catch (error) {
        if (getInitialType(this.embeddedEncodingOperation) !== 'identifier') {
          this.setState({ processing: false })
          this.attachAutomaticReaderStop()
        } else {
          this.removeTagReadFromReader(tagsRead)
          // this.closeVirginTagModal()
        }
        if (!forceAssociation) {
          await this.startAntenna()
        }
        this.isEncoding = false
        this.encodingError(error, 'Unknown error during encoding validation')
      }
    } else {
      this.setState({ processing: false })
      this.encodingError(undefined, __(T.error.item_creation_error))
    }
  }

  _confirmSuccess = async (result?) => {
    const confirmData = this.getConfirmData()
    await ShippingExtensions.afterConfirm(this.operation, confirmData, result || {})
    this.setState({ confirming: false })
    if (result?.status === 'READY_TO_RECEIVE' || result?.status === 'RECEIVED') {
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.parcel_success),
        status: 'success',
      })
    } else {
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
    }

    if (['hideSuccessModal', 'changeInput', 'disabled'].includes(this.operation.postConfirmAction)) {
      this.onClear()
    } else {
      //keepInput not managed
    }
  }

  getProcessingMessage() {
    return __(this.state.processing === 'nfcDecryption' ? T.messages.nfcDecryption : T.messages.operation_in_progress)
  }

  goBack = () => {
    this.goDashboard()
  }

  showClearAndConfirm() {
    const { confirming, formData } = this.state
    const showClearButton = !!formData
    const showConfirmButton = this.canShowConfirmButton()
    const confirmButtonEnabled = this.confirmButtonEnabled()

    return (
      <Box row>
        {showClearButton && (
          <Button
            flex
            variant="default"
            style={{ marginRight: showClearButton && showConfirmButton ? 10 : 0 }}
            title={__(T.misc.clear)}
            onClick={this.onClear}
          />
        )}
        {showConfirmButton && (
          <Button
            flex
            style={{ marginTop: 0 }}
            variant={confirmButtonEnabled ? 'primary' : 'ignore'}
            loading={confirming}
            disabled={!confirmButtonEnabled}
            title={__(T.misc.confirm)}
            onClick={this.onConfirm}
          />
        )}
      </Box>
    )
  }

  getFilteredIdentifiersToRender = (identifiers: ConfigurationDetail[]) => {
    return identifiers
  }

  getIdentifiersLabelsByType: () => Partial<Record<IdentifierType, string>> | undefined = () => {
    return undefined
  }

  getProductInfoFields: () => ProductInfoField[] | undefined = () => {
    return undefined // use defaults
  }

  getHideIdentifierByType = (): IdentifierType[] | undefined => {
    return undefined
  }

  getHideIdentifierCodeByType = (): IdentifierType[] | undefined => {
    return undefined
  }

  getHideIdentifierZones: () => boolean = () => {
    return false
  }

  render() {
    const {
      items,
      formData,
      loading,
      parcel,
      product,
      sseStatus,
      processing,
      renderIteration,
      removeModeEnabled,
      encodingValidation,
      encodingValidationResponse,
      associationStatus,
      initReaderTags,
    } = this.state

    let tagCounterExpecteds = 0
    let tagCounterUnexpecteds = 0
    if (parcel?.checklist) {
      const { expecteds, unexpecteds, overQty } = getChecklistItemsCount(
        items,
        this.operation,
        ShippingExtensions,
        parcel.checklist,
        parcel.products
      )
      tagCounterExpecteds = expecteds
      tagCounterUnexpecteds = unexpecteds + overQty
    }
    const identifiers = encodingValidation?.identifiers

    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        alertOnBackPress={
          this.operation.showAlertOnExit &&
          !this.isBatchReadingMode &&
          items.filter(filterIgnoredAndErrorItems).length > 0
        }
        loading={loading}
        headerRight={
          <Box style={{ flexDirection: 'row' }}>
            {hasAsyncConfirm(this.operation) ? <Stoplight active={sseStatus} /> : undefined}
            {this.operationRemoveModeAllowed && <RemoveMode onRemoveChange={this.onRemoveChange} />}
          </Box>
        }
        header={{
          details: {
            data: _cloneDeep(formData),
            formSchema: this.formSchema,
            asn: this.asn,
            setFormData: this.setFormData,
            resetFormData: async (fd) => {
              this.setState({ formData: fd })
            },
            operationId: this.operation.id,
          },
        }}
        forceDetails="top"
        userSettings={{ operation: this.operation, settings: this.operation.userSettings }}
        onUserSettingChanged={(name, newValue) => this.handleOnUserSettingChanged(name, newValue)}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex>
            {this.embeddedEncodingOperation && (
              <EncodingForm
                key={renderIteration}
                overrideFormSchema={this.formSchema}
                overrideProductInfoFields={this.getProductInfoFields()}
                onSubmit={this.onSubmitForm}
                product={product ?? EncodingExtensions.getEncodingProduct(encodingValidationResponse)}
                operation={this.embeddedEncodingOperation}
                data={formData}
                updateFormSchemaData={(data) => this.setState({ formData: data })}
                onProductionOrderRowClear={this.onClear}
                // onIdentifierClear={this.clearReads}
                // resetStateCounter={resetFormCounter}
                disabledClear
                disabledClearProduct
                // disableInput
                style={{ width: '100%' }}
                flex={false}
              />
            )}
            {formData && (
              <AntennaButton
                ref={this.antennaRef}
                decodeRequest={this.getDecodeRequest()}
                onItemDecoded={this.onDecodedItemCallback}
                onTagReadCallback={this.onTagRead}
                onClear={this.onClear}
                initReaderTags={initReaderTags}
                hideClear
                onStart={async () => {
                  const res = await EncodingExtensions.getItemConfiguration(this.embeddedEncodingOperation, formData)
                  RfidReader.clear()
                  RfidReader.setAutomaticStop(this.embeddedEncodingOperation.autostopAntennaTimeout > 0)
                  RfidReader.setAutomaticStopTime(this.embeddedEncodingOperation.autostopAntennaTimeout)
                  this.setState({
                    associationStatus: 'TO_BE_READ',
                    encodingValidation: res.encodingValidation,
                  })
                }}
                overrideStartLabel={associationStatus === 'ERROR' ? __(T.misc.retry) : __(T.misc.start)}
                // disabled={associationStatus === 'ERROR'}
              />
            )}
            <Box style={{ marginBottom: 40 }} />
            <TagCounter
              detected={items.filter(filterIgnoredAndErrorItems).length}
              expected={tagCounterExpecteds}
              unexpected={tagCounterUnexpecteds}
              layout={{ disableSpacer: !formData }}
            />
            {checkItemsCounterError(items) && (
              <>
                <Spacer />
                <OperationReadingCounters
                  operation={this.operation}
                  items={items}
                  hideIdentifiersByType={this.getHideIdentifierByType()}
                  hideIdentifiersZones={this.getHideIdentifierZones()}
                  overrideProductInfoFields={this.getProductInfoFields()}
                  disableModalRowCounters
                />
                <Spacer />
              </>
            )}
          </Box>
          {this.showClearAndConfirm()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          {identifiers && encodingValidation && (
            <EncodingIdentifers
              identifiers={this.getFilteredIdentifiersToRender(identifiers)}
              clearReads={() => {
                this.clearReads()
              }}
              onTagRead={this.onTagRead}
              encodingValidation={encodingValidation}
              associationStatus={associationStatus}
              disabledRetry={this.getDisableRetry()}
              lineOfMax3Identifiers
              layout={{ minHeight: '12vh', maxHeight: '12vh', iconSize: 60 }}
              marginBottom={10}
              fullWidthAssociationBox={this.getFullWidthAssociationBox()}
              labelsByType={this.getIdentifiersLabelsByType()}
              hideIdentifiersCodeByType={this.getHideIdentifierCodeByType()}
            />
          )}
          {formData && <Box style={{ height: 50 }} />}
          <OperationReadingList
            removeItemCallback={this.removeItem}
            extension={ShippingExtensions}
            items={items}
            operation={this.operation}
            checklist={parcel ? parcel.checklist : undefined}
            products={parcel ? parcel.products : undefined}
            removeModeEnabled={removeModeEnabled && this.isRemoveModeBySku}
            emptyMessage={null}
            hideIdentifiersByType={this.getHideIdentifierByType()}
            hideIdentifiersZones={this.getHideIdentifierZones()}
            overrideProductInfoFields={this.getProductInfoFields()}
          />
          <WorkstationEmulationInfoBox />
        </Page.Content>
        {!sseStatus && (
          <FullLoadingLayer
            message={!sseStatus ? __(T.messages.connection_in_progress) : __(T.messages.operation_in_progress)}
          />
        )}
        {processing && <FullLoadingLayer message={this.getProcessingMessage()} />}
      </Page>
    )
  }
}
