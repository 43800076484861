import { useState } from 'react'
import styled from '@emotion/styled'
import { DecodedItem, IdentifierType, ProductInfoField } from 'stylewhere/api'
import { Box, DecodedItemsModal, SmallTextCounter } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { OperationConfig } from 'stylewhere/shared'

type Props = {
  items: DecodedItem[]
  operation?: OperationConfig
  hideIdentifiersByType?: IdentifierType[]
  hideIdentifiersZones?: boolean
  overrideProductInfoFields?: ProductInfoField[]
  disableModalRowCounters?: boolean
}

export const OperationReadingCounters: React.FC<Props> = ({
  items,
  operation,
  hideIdentifiersByType,
  hideIdentifiersZones,
  overrideProductInfoFields,
  disableModalRowCounters,
}) => {
  const [modalVisible, setModalVisible] = useState<false | 'error' | 'warning' | 'ignored' | 'ignoredWithReason'>(false)
  const errorItems = items.filter((itm) => itm.item?.status === 'error')
  const warningItems = items.filter((itm) => itm.item?.status === 'warning')
  const ignoredItems = items.filter((itm) => itm.item?.status === 'ignored')
  const ignoredWithReasonItems = items.filter((itm) => itm.item?.status === 'ignoredWithReason')

  let modalTitle = ''
  if (modalVisible === 'error') {
    modalTitle = __(T.fields.error_items)
  }
  if (modalVisible === 'warning') modalTitle = __(T.fields.warning_items)
  if (modalVisible === 'ignored') modalTitle = __(T.fields.ignored_items)
  if (modalVisible === 'ignoredWithReason') modalTitle = __(T.fields.ignored_items)
  return (
    <Container style={{ flexWrap: 'wrap', gap: 12 }} row justify="space-evenly">
      {errorItems.length > 0 && (
        <SmallTextCounter
          counter={errorItems.length}
          title={__(T.error.error)}
          status="error"
          onClick={() => setModalVisible('error')}
        />
      )}
      {warningItems.length > 0 && (
        <SmallTextCounter
          counter={warningItems.length}
          title={__(T.misc.warning)}
          status="warning"
          onClick={() => setModalVisible('warning')}
        />
      )}
      {ignoredItems.length > 0 && (
        <SmallTextCounter
          counter={ignoredItems.length}
          title={__(T.misc.ignored)}
          status="ignored"
          onClick={() => setModalVisible('ignored')}
        />
      )}
      {ignoredWithReasonItems.length > 0 && (
        <SmallTextCounter
          counter={ignoredWithReasonItems.length}
          title={__(T.misc.ignored_with_reason)}
          status="ignoredWithReason"
          onClick={() => setModalVisible('ignoredWithReason')}
        />
      )}

      {modalVisible === 'error' && (
        <DecodedItemsModal
          decodedItems={errorItems}
          operation={operation}
          isOpen
          onClose={() => setModalVisible(false)}
          title={modalTitle}
        />
      )}
      {modalVisible === 'warning' && (
        <DecodedItemsModal
          decodedItems={warningItems}
          operation={operation}
          isOpen
          onClose={() => setModalVisible(false)}
          title={modalTitle}
          hideIdentifiersByType={hideIdentifiersByType}
          hideIdentifiersZones={hideIdentifiersZones}
          overrideProductInfoFields={overrideProductInfoFields}
          disableRowCounters={disableModalRowCounters}
        />
      )}
      {modalVisible === 'ignored' && (
        <DecodedItemsModal
          decodedItems={ignoredItems}
          operation={operation}
          isOpen
          onClose={() => setModalVisible(false)}
          title={modalTitle}
          hideIdentifiersByType={hideIdentifiersByType}
          hideIdentifiersZones={hideIdentifiersZones}
          overrideProductInfoFields={overrideProductInfoFields}
          disableRowCounters={disableModalRowCounters}
        />
      )}
      {modalVisible === 'ignoredWithReason' && (
        <DecodedItemsModal
          decodedItems={ignoredWithReasonItems}
          operation={operation}
          isOpen
          onClose={() => setModalVisible(false)}
          title={modalTitle}
          hideIdentifiersByType={hideIdentifiersByType}
          hideIdentifiersZones={hideIdentifiersZones}
          overrideProductInfoFields={overrideProductInfoFields}
          disableRowCounters={disableModalRowCounters}
        />
      )}
    </Container>
  )
}

const Container = styled(Box)``
